  /* .form {
    width: 50%;
} */
/* .se.fileimport {width: 1800px; padding: 8px;} */ */
/* .segment.importfile {width: 500px; padding-top: 8px; padding-bottom: 8px;} */
/* .segment.importlist {width: 1000px; padding: 8px;} */

/* .segment.rawdata {padding: 8px; overflow: auto; height: 400px} */

.authorisations {width: 100vw; height: calc(100vh - 220px); overflow: auto;}

.menubar {margin: 0px 0px 0px 0px !important; padding: 0px}
/* .item {padding: 6px !important} */
.mpxnlist {width: 100%; max-height: 250px; overflow: auto;  margin-top: 10px; margin-bottom: 10px;}
.totalboxes {margin-top: 10px; margin-bottom: 10px;}

.input.pageinput {margin: 0px !important; padding: 0px !important; width: 75px; margin-left: 5px !important; margin-right: 5px !important}

.pagination.menu .active.item {width:inherit !important; color: inherit !important; background-color: #E0E0E0 !important;}
.pagination.menu .item:hover {width:inherit !important; color: inherit !important; background-color: #F2F2F2 !important;}
